import React from 'react';
import {
    formatNumberWithTwoDigits,
    commonTexts,
    useCountDown,
} from 'onair_frontend-lib';

import messages from '../templates/event-page/messages';

import type { FC } from 'react';

export type EventCountdownBannerProps = {
    startDate: Date;
    playingNow: boolean;
};

export const EventCountdownBanner: FC<EventCountdownBannerProps> = ({
    startDate,
    playingNow,
}) => {
    const timeUntil = useCountDown(startDate);
    if (!timeUntil) return null;

    const eventBannerType = playingNow ? 'bannerLive' : 'banner';
    return (
        <div className="countdown-banner py-1">
            <p className="m-0 mb-1 body-text">
                {messages[eventBannerType].title}
                <span className="body-title">
                    <b>{messages[eventBannerType].title_second_part}</b>
                </span>
                {messages[eventBannerType].title_third_part}
            </p>
            <p className="m-0 body-text">
                <span className="body-title countdown-banner__countdown">
                    {timeUntil.days > 0 &&
                        `${formatNumberWithTwoDigits(timeUntil.days)} ${
                            commonTexts.days
                        } `}
                    {timeUntil.hours > 0 &&
                        `${formatNumberWithTwoDigits(timeUntil.hours)} ${
                            commonTexts.hours_short
                        } `}
                    {timeUntil.minutes > 0 &&
                        `${formatNumberWithTwoDigits(timeUntil.minutes)} ${
                            commonTexts.minutes_short
                        } `}
                    {timeUntil.seconds > 0 &&
                        `${formatNumberWithTwoDigits(timeUntil.seconds)} ${
                            commonTexts.second_short
                        }`}
                </span>
            </p>
        </div>
    );
};
