import * as React from 'react';

export default () => (
    <svg
        width="39"
        height="32"
        viewBox="0 0 39 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.99969 11.0167V14.1667H12.9979V16.5001H16.6635V14.1667H18.089V10.8612H16.6635V2.50006H12.6832L6.99969 11.0167ZM10.7208 10.8612L13.2756 6.79728V10.8612H10.7208Z"
            fill="#F7F5F8"
        />
        <path
            d="M23.5208 10.4917L27.6121 16.5001H31.9997L26.9642 9.44173L31.759 2.79173H27.5751L23.5208 8.70284V2.79173H19.8922V16.5001H23.5208V10.4917Z"
            fill="#F7F5F8"
        />
        <path
            d="M7.70922 30.0001V22.8001H8.54922V30.0001H7.70922Z"
            fill="#F7F5F8"
        />
        <path
            d="M3.72672 30.1201C3.24672 30.1201 2.81005 30.0268 2.41672 29.8401C2.03005 29.6468 1.72005 29.3501 1.48672 28.9501C1.25339 28.5501 1.13672 28.0401 1.13672 27.4201V23.0001H1.97672V27.4301C1.97672 28.1101 2.14005 28.6034 2.46672 28.9101C2.79339 29.2168 3.22005 29.3701 3.74672 29.3701C4.27339 29.3701 4.69672 29.2168 5.01672 28.9101C5.33672 28.6034 5.49672 28.1101 5.49672 27.4301V23.0001H6.33672V27.4201C6.33672 28.0401 6.22005 28.5501 5.98672 28.9501C5.75339 29.3501 5.44005 29.6468 5.04672 29.8401C4.65339 30.0268 4.21339 30.1201 3.72672 30.1201Z"
            fill="#F7F5F8"
        />
        <path
            d="M10.816 29.6701C11.076 29.8901 11.4327 30.0001 11.886 30.0001H12.646V29.2801H12.126C11.806 29.2801 11.5827 29.2268 11.456 29.1201C11.3293 29.0068 11.266 28.7934 11.266 28.4801V25.7501H12.726V25.0401H11.266V23.8501H10.536L10.426 25.0401H9.56602V25.7501H10.426V28.4801C10.426 29.0534 10.556 29.4501 10.816 29.6701Z"
            fill="#F7F5F8"
        />
        <path
            d="M13.7932 30.0001V25.0401H14.5532L14.6232 25.9901C14.7765 25.6634 15.0099 25.4034 15.3232 25.2101C15.6365 25.0168 16.0232 24.9201 16.4832 24.9201V25.8001H16.2532C15.9599 25.8001 15.6899 25.8534 15.4432 25.9601C15.1965 26.0601 14.9999 26.2334 14.8532 26.4801C14.7065 26.7268 14.6332 27.0668 14.6332 27.5001V30.0001H13.7932Z"
            fill="#F7F5F8"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.046 29.9101C18.3193 30.0501 18.6627 30.1201 19.076 30.1201C19.5293 30.1201 19.8893 30.0234 20.156 29.8301C20.4293 29.6301 20.636 29.3901 20.776 29.1101L20.826 30.0001H21.576V26.9501C21.576 26.3368 21.3993 25.8468 21.046 25.4801C20.6994 25.1068 20.1793 24.9201 19.486 24.9201C19.1327 24.9201 18.7993 24.9868 18.486 25.1201C18.1727 25.2468 17.9093 25.4334 17.696 25.6801C17.4893 25.9268 17.3694 26.2301 17.336 26.5901H18.196C18.256 26.2768 18.4027 26.0401 18.636 25.8801C18.876 25.7134 19.1593 25.6301 19.486 25.6301C19.866 25.6301 20.1693 25.7434 20.396 25.9701C20.6227 26.1901 20.736 26.5168 20.736 26.9501V27.0101H19.396C18.7293 27.0101 18.2027 27.1468 17.816 27.4201C17.4293 27.6934 17.236 28.0834 17.236 28.5901C17.236 28.8634 17.3027 29.1168 17.436 29.3501C17.5693 29.5834 17.7727 29.7701 18.046 29.9101ZM20.026 29.1601C19.7994 29.3268 19.526 29.4101 19.206 29.4101C18.8594 29.4101 18.5893 29.3334 18.396 29.1801C18.2093 29.0201 18.116 28.8101 18.116 28.5501C18.116 28.2968 18.216 28.0868 18.416 27.9201C18.6227 27.7468 18.9727 27.6601 19.466 27.6601H20.736V27.6701C20.736 27.9634 20.676 28.2434 20.556 28.5101C20.436 28.7768 20.2593 28.9934 20.026 29.1601Z"
            fill="#F7F5F8"
        />
        <path
            d="M25.5327 30.0001V23.0001H26.3727V26.0901H30.0027V23.0001H30.8427V30.0001H30.0027V26.7801H26.3727V30.0001H25.5327Z"
            fill="#F7F5F8"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.3198 23.0001V30.0001H34.4698C35.7031 30.0001 36.6098 29.6901 37.1898 29.0701C37.7764 28.4434 38.0698 27.5934 38.0698 26.5201C38.0698 25.4268 37.7764 24.5668 37.1898 23.9401C36.6098 23.3134 35.7031 23.0001 34.4698 23.0001H32.3198ZM34.4498 29.3001H33.1598V23.7001H34.4498C35.1298 23.7001 35.6698 23.8134 36.0698 24.0401C36.4764 24.2668 36.7664 24.5901 36.9398 25.0101C37.1131 25.4301 37.1998 25.9334 37.1998 26.5201C37.1998 27.0934 37.1131 27.5901 36.9398 28.0101C36.7664 28.4234 36.4764 28.7434 36.0698 28.9701C35.6698 29.1901 35.1298 29.3001 34.4498 29.3001Z"
            fill="#F7F5F8"
        />
    </svg>
);
