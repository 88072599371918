/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
"use client";
import * as snippet from "@segment/snippet";
import { simpleActionTrack } from "onair_frontend-lib";

import type { AnalyticsBrowser } from "@segment/analytics-next";
import type { TrackingPlatform } from "onair_frontend-lib";

export const renderSegmentSnippet = (segmentAnalyticsWriteKey?: string) => {
	const options = {
		apiKey: segmentAnalyticsWriteKey ?? process.env.SEGMENT_ANALYTICS_WRITE_KEY,
		page: true,
	};

	return snippet.min(options);
};

export const sharedAnalyticsProperties: {
	trackCallback?: typeof AnalyticsBrowser.prototype.track;
	platform: TrackingPlatform;
} = {
	trackCallback:
		typeof window === "undefined" ? undefined : window.analytics?.track,
	platform: "Web",
};

export const trackHelpdeskViewed = () => {
	simpleActionTrack({
		...sharedAnalyticsProperties,
		trackCallback: window?.analytics?.track,
		action: "Helpdesk Viewed",
	});
};
