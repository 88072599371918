import React from 'react';
import Icon from '../Icon';
import LinkInternal from '../LinkInternal';
import ROUTES from '../../constants/routes';

const NavbarSearch = () => (
    <>
        <div className="col navbar__link navbar__link--icon d-md-block flex-grow-0">
            <LinkInternal to={ROUTES.search}>
                <Icon key="search-icon" icon="oa-search" />
            </LinkInternal>
        </div>
    </>
);

export default NavbarSearch;
