"use client";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import * as EmailValidator from "email-validator";
import { getEnv } from "onair_frontend-lib";
import { NewsletterSignupStatus } from "./types";
import { NewsletterSuccess } from "./NewsletterSucces";
import { NewsletterInput } from "./NewsletterInput";
import { getEnvironmentVariableServerSide } from "@/utils/server-functions/getEnvServerSide";

const NewsletterSignup = () => {
	const [loading, setLoading] = useState(false);
	const [validationError, setValidationError] = useState(false);
	const [signUpStatus, setSignUpStatus] = useState<
		NewsletterSignupStatus | undefined
	>(undefined);
	const [emailValue, setEmailValue] = useState("");
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		if (validationError) setValidationError(false);
		setEmailValue(e.target.value);
	};

	const subscribe = useCallback(async () => {
		setLoading(true);
		if (emailValue && EmailValidator.validate(emailValue)) {
			const formData = new FormData();
			formData.append("EMAIL", emailValue);
			try {
				// Fetch does not use cookie by default, so any 3rd party cookies will not be saved
				const { SENDINBLUE_ENDPOINT } = await getEnvironmentVariableServerSide(['SENDINBLUE_ENDPOINT',]);
				
				const response = await fetch(SENDINBLUE_ENDPOINT!, {
					method: "POST",
					body: formData,
					cache: "no-store",
				});
				// Only show a success notification if 200 and success: true are returned
				const { success } = await response.json();
				setSignUpStatus(!response.ok || !success ? "error" : "success");
			} catch (error) {
				setSignUpStatus("error");
			} finally {
				setLoading(false);
			}
		} else {
			setValidationError(true);
			setLoading(false);
		}
	}, [setSignUpStatus, setValidationError, emailValue]);

	if (!isMounted) return null;

	return (
		<div className="newsletter-signup py-4 py-md-7">
			<div className="container">
				<div className="d-flex d-row justify-content-between">
					<div className="column col-md-12 col-lg-10 col-xl-8 m-md-auto text-center">
						{signUpStatus ? (
							<div className="row flex-grow-1">
								<NewsletterSuccess />
							</div>
						) : (
							<NewsletterInput
								subscribe={subscribe}
								onChangeInput={onChangeInput}
								loading={loading}
								validationError={validationError}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewsletterSignup;
