import React, { FC, ReactNode } from 'react';
import {
    NotificationType,
    LinkType,
} from 'onair_frontend-lib';
import messages from './messages';
import OnAirNotification from '../OnAirNotification';

type Props = {
    title: string;
    message: string;
    details?: string;
    optionsTitle?: string;
    isEventError?: boolean;
    children: ReactNode;
    error?: string
};

const ErrorComponent: FC<Props> = ({
    title,
    message,
    details,
    optionsTitle,
    children,
    isEventError,
    error
}) => (
    <div className="container">
        <div className="row mb-2">
            <div className="col">
                <h3 className="title">{title}</h3>
            </div>
        </div>
        <div className="row mb-4">
            <div className="col">
                <h5 className="section-title">{message}</h5>
            </div>
        </div>
        <div className="row mb-4">
            <div className="col">
                <p className="body-text color-accent-light">{details ?? ''}</p>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <h5 className="body-text">{optionsTitle}</h5>
            </div>
        </div>
        <ul className="unstyled-list">{children}</ul>
        {isEventError && (
            <div className="mt-4 mt-sm-7 mb-4 mb-sm-7">
                <OnAirNotification
                    className="mt-2"
                    text={{
                        preText: error ? error : messages.unsupported_devices_pre_text,
                        linkText: error ? '' : messages.unsupported_devices_link_text,
                        linkTarget:
                            'https://helpdesk.onair.events/support/solutions/articles/80000958802-supported-devices-and-platforms',
                        linkType: LinkType.LINK_PRIMARY,
                        isExternal: !error,
                    }}
                    type={NotificationType.info}
                    testId="notification"
                />
            </div>
        )}
    </div>
);

export default ErrorComponent;
