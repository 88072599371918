import classNames from "classnames";
import React from "react";
import { LinkType } from "onair_frontend-lib";

import Icon from "./Icon";

import type { FC, ReactNode } from "react";
import type { LinkBase } from "../types/link";

export type LinkExternalProps = Omit<LinkBase, "text"> & {
	to?: string;
	children?: ReactNode;
	className?: string;
	onClick?: (e: any) => void; // bad practice 😢
	icon?: string;
	altText?: string;
	ariaLabel?: string;
	shouldOpenInNewTab?: boolean;
	shouldFollow?: boolean;
};

const LinkExternal: FC<LinkExternalProps> = ({
	to,
	children,
	icon,
	className,
	onClick,
	altText,
	type = LinkType.LINK_PRIMARY,
	ariaLabel,
	shouldOpenInNewTab = true,
	shouldFollow = false,
}) => {
	const linkRel = shouldFollow ? undefined : "noopener noreferrer nofollow";

	const linkTarget = to && shouldOpenInNewTab ? "_blank" : undefined;

	return (
		<a
			href={to}
			rel={linkRel}
			target={linkTarget}
			className={classNames("link", className, type)}
			onClick={onClick}
			aria-label={ariaLabel}
		>
			{icon && (
				<Icon
					icon={icon}
					className={classNames({ "me-1": children })} // icon margin-right unnecessary if there is no children also affects visual badly
					altText={altText}
				/>
			)}
			{children}
		</a>
	);
};
export default LinkExternal;
