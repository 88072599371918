import React from 'react';
import classNames from 'classnames';
import ROUTES from '../../constants/routes';
import LinkExternal from '../LinkExternal';
import PlayStoreLogo from '../svgs/PlayStoreLogo';
import AppStoreLogo from '../svgs/AppStoreLogo';
import { usePlatform } from '../../hooks/usePlatform';
import {
    AppStoreBannerSources,
    AvailableAppStores,
    LinkType,
    trackAppStoreBanners,
} from 'onair_frontend-lib';
import { sharedAnalyticsProperties } from '@/utils/analytics';

interface AppStoreBannerProps {
    centered?: boolean;
    component: AppStoreBannerSources;
}

export const AppStoreBanner = ({
    centered,
    component,
}: AppStoreBannerProps) => {
    const { platform } = usePlatform();

    const handleAppStoreBannerClick = (store: AvailableAppStores) => {
        trackAppStoreBanners({
            ...sharedAnalyticsProperties,
            trackCallback: window?.analytics?.track,
            store,
            device: 'Mobile App',
            platform: 'Web',
            source: component,
            url: window.location.href,
        });
    };

    return (
        <div
            className={classNames(
                'app-store-banner d-flex flex-row align-items-start w-100',
                {
                    'justify-content-center': centered,
                    'align-items-center': centered,
                    'text-center': centered,
                },
            )}
        >
            {(platform === 'ios' || platform === 'web') && (
                <div className="item d-flex flex-row">
                    <LinkExternal
                        to={ROUTES.appleStore}
                        className="app-store-banner__icon d-flex align-items-center p-0"
                        type={LinkType.LINK_STYLED_IN_COMPONENT}
                        onClick={() => handleAppStoreBannerClick('Apple')}
                    >
                        <AppStoreLogo />
                    </LinkExternal>
                </div>
            )}
            {(platform === 'android' || platform === 'web') && (
                <div className="item d-flex flex-row">
                    <LinkExternal
                        to={ROUTES.playStore}
                        className="app-store-banner__icon d-flex align-items-center p-0"
                        type={LinkType.LINK_STYLED_IN_COMPONENT}
                        onClick={() => handleAppStoreBannerClick('Google')}
                    >
                        <PlayStoreLogo />
                    </LinkExternal>
                </div>
            )}
        </div>
    );
};
