'use client';
export const logPageViewToSegment = (page?: string) => {
    // Disable it for OAR-4853
    // if (!process.env.NEXT_PUBLIC_TEST_ONE_TRUST === 'true') {
    sessionStorage.setItem(
        `page-${new Date().getTime()}`,
        page ?? window.location.pathname,
    );

    window.analytics?.page(page);
    // }
};
