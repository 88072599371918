import React from 'react';

import Script from 'next/script';

import { ON_AIR_ORGANIZATION_INFO } from '../components/contact/messages';

import type {
    ContentfulEvent,
    ContentfulEventCarousel,
} from 'onair_frontend-lib';

export const HTTPS_PREFIX = 'https://';
export const HTTP_PREFIX = 'http://';

/**
 * @param urlToCheck
 * @param slug
 * @returns The canonical url of the page
 */
export const getCanonicalLink = (urlToCheck: string, slug: string): string => {
    let onAirUrl = urlToCheck;

    // check if http is included
    const isHttpIncluded = onAirUrl.includes(HTTP_PREFIX);

    if (isHttpIncluded) {
        // if http:// is included remove it and add the https:// one
        onAirUrl = `${HTTPS_PREFIX}${urlToCheck.slice(7)}`;
    }
    const isHttpsIncluded = onAirUrl.includes(HTTPS_PREFIX);
    // add https prefix if not included
    const onAirPrefix = isHttpsIncluded
        ? onAirUrl
        : `${HTTPS_PREFIX}${onAirUrl}`;

    const isStartingWithSlash = slug?.charAt(0) === '/';

    return isStartingWithSlash
        ? `${onAirPrefix}${slug}`
        : `${onAirPrefix}/${slug}`;
};

export const renderSchemaCarousel = (
    slug: string,
    carousel: ContentfulEventCarousel,
) => {
    if (!carousel?.content) return <script></script>;
    // Pages we want to render the schema.org
    const pagesToRender = ['/', '/about-us', 'contact'];
    const includedPage = pagesToRender.includes(slug);

    if (!includedPage) return <script></script>;

    const schema = (
        <Script type="application/ld+json" id="carousel">
            {JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'ItemList',
                itemListElement: carousel.content?.map((item, index) => {
                    const transformedItem = item as ContentfulEvent;
                    return {
                        '@type': 'ListItem',
                        position: index + 1,
                        url: getCanonicalLink(
                            process.env.SITE_URL ?? 'https://onair.events',
                            transformedItem.slug,
                        ),
                        name: transformedItem.title,
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        image: `${transformedItem.poster?.asset?.url}`,
                    };
                }),
            })}
        </Script>
    );

    return schema;
};

/**
 * @param slug
 * @returns The On Air's organization type from Schema.org if the slug is included to pagesToRender array else "undefined"
 */
export const renderSchemaOrganization = (slug: string) => {
    // Pages we want to render the schema.org
    const pagesToRender = ['/', 'about-us', 'contact'];
    const includedPage = pagesToRender.some((page) => page === slug);

    if (!includedPage) return <script></script>;

    return (
        <Script type="application/ld+json" id="organization">
            {JSON.stringify(ON_AIR_ORGANIZATION_INFO)}
        </Script>
    );
};
