import * as React from 'react';

export default () => (
    <svg
        width={120}
        height={48}
        x="0px"
        y="0px"
        viewBox="0 0 120 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M115.743 0C118.094 0 120 1.869 120 4.174v39.652c0 2.305-1.906 4.174-4.257 4.174H4.257C1.906 48 0 46.131 0 43.826V4.174C0 1.869 1.906 0 4.257 0h111.486zm-.202 4.373H4.459v39.254h111.082V4.373zm-88.683 8.745c2.351 0 4.257 1.869 4.257 4.174v13.515c0 2.306-1.906 4.174-4.257 4.174h-4.865c-2.35 0-4.256-1.868-4.256-4.174V17.293c0-2.305 1.905-4.174 4.256-4.174h4.865zm-.203 4.373h-4.46v13.118h4.46V17.49zm44.9-4.274c2.16 0 3.916 1.701 3.951 3.812v17.952h-4.459v-8.813l-4.46.012v8.801h-4.459V17.093c0-2.119 1.735-3.84 3.888-3.875h5.538zm-.508 4.373v4.205l-4.46.012V17.59h4.46zm27.163-4.373c2.216 0 4.017 1.745 4.053 3.91l.001.066v5.068c0 2.173-1.779 3.94-3.987 3.974l-.067.001h-.227l4.272 8.745H97.62l-4.275-8.622v8.622h-4.46V17.192c0-2.173 1.78-3.939 3.987-3.974h5.337zm-.406 4.373h-4.46v4.273h4.46V17.59zM84.41 34.981V13.118h-4.46v21.863h4.46zm-44.38-21.863h-4.58v21.863h4.58V24.05L44.46 34.98h4.533V13.118h-4.532v10.857l-4.432-10.857z"
            fill="#F1F1F1"
        />
    </svg>
);
