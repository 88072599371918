import React from 'react';
import ShellClientSide from './ShellClientSide';

import type { FooterProps } from './footer';
import type { OGP } from '../types/page-context';
import type { FC, ReactNode } from 'react';
import type { ContentfulEventCarousel } from 'onair_frontend-lib';

interface ShellProps {
    readonly children: ReactNode;
    readonly isFooterTopLineDisabled?: FooterProps['disableTopLine'];
    readonly isNewsletterSignupVisible?: boolean;
    readonly ogp?: OGP;
    readonly schemaData?: {
        carousel?: ContentfulEventCarousel;
        prerendered?: string;
    };
    readonly banner?: ReactNode;
    readonly pathname?: string;
}

const Shell: FC<ShellProps> = ({
    children,
    isFooterTopLineDisabled,
    ogp,
    schemaData,
    banner,
    isNewsletterSignupVisible,
}) => {
    const { slug } = ogp ?? {};

    return (
        <>
            <ShellClientSide
                isFooterTopLineDisabled={isFooterTopLineDisabled}
                banner={banner}
                slug={slug}
                ogp={ogp}
                isNewsletterSignupVisible={isNewsletterSignupVisible}
                schemaData={schemaData}
            >
                {children}
            </ShellClientSide>
        </>
    );
};

export default Shell;
