export const CONTACT_PAGE_MESSAGES = {
	title: "get in touch",
	description:
		"In order to contact the On Air team about any general enquiries, collaborations, brand partnerships or business enquiries, please use the contact form. ",
	contact_form_submit_success:
		"Your message has been sent successfully. One of our helpdesk employees will reach out to you shortly.",
	contact_form_submit_error:
		"An unexpected error has occured, please try again later",
	delete_account: {
		subject: "[OA] Delete account",
		description: "Please delete my account.",
		notification_warning:
			"Please note that deleting your account is irreversible, and that you will lose access to all your purchased shows",
	},
	contact_form_category_error: {
		part1: "In order to choose this category you need to be ",
		part2: "logged in",
	},
};

export const ON_AIR_ORGANIZATION_INFO = {
	"@context": "https://schema.org",
	"@type": "Organization",
	"@id": "https://onair.events",
	name: "On Air B.V.",
	description:
		"On Air offers access to the highest quality cinematic livestream shows from anywhere around the world, both live and on demand.",
	address: {
		"@type": "PostalAddress",
		streetAddress: "Eemnesserweg 20",
		addressLocality: "Baarn",
		addressCountry: "NL",
		postalCode: "3741GA",
	},
	email: "helpdesk@onair.events",
	url: "https://onair.events",
	logo: "https://images.ctfassets.net/s26j9zm7rn0w/5dHwQ9CBZCh00aw012LAeV/dbc27d5693a69914317a975063b06370/on_air_logo_round.png",
	sameAs: [
		"https://www.facebook.com/onairevents/",
		"https://www.instagram.com/onairevents/",
		"https://www.linkedin.com/company/onairbv/",
		"https://www.tiktok.com/@onair.events",
		"https://twitter.com/on_air_events",
		"https://www.youtube.com/channel/UCSxg4fRhV3garyt15l4tZ8w",
	],
	vatID: "NL859572146B01",
	taxID: "73550019",
};
