import React, { FC } from "react";
import classNames from "classnames";
import LinkInternal from "../LinkInternal";
import Socials from "../Socials";
import ROUTES from "../../constants/routes";
import { onAirSocialsLinks } from "../../utils/social";
import LinkExternal from "../LinkExternal";
import OnairLogo from "../svgs/OnairLogo";
import { Logos } from "./Logos";
import messages from "./messages";
import { LinkType } from "onair_frontend-lib";
import { trackHelpdeskViewed } from "../../utils/analytics";

export interface FooterProps {
	disableTopLine?: boolean;
}

const Footer: FC<FooterProps> = ({ disableTopLine }) => (
	<footer
		className={classNames("footer py-4 py-md-7", {
			"footer--no-topline": disableTopLine,
		})}
	>
		<div className="container">
			<div className="d-flex flex-column">
				<div className="d-flex flex-column footer__section-links flex-md-row justify-content-center align-items-center justify-content-md-start align-items-md-start order-2 order-md-1">
					<div className="footer__menu-group footer__menu-links col-md-4 col-lg-4">
						<div className="footer__menu-heading">
							<p className="footer__title">{messages.company}</p>
						</div>
						<ul className="footer-text">
							<li>
								<LinkInternal
									to={ROUTES.about}
									type={LinkType.LINK_STYLED_IN_COMPONENT}
									className="footer-link footer-text"
								>
									{messages.about_us}
								</LinkInternal>
							</li>
							<li>
								<LinkExternal
									to={ROUTES.news}
									type={LinkType.LINK_STYLED_IN_COMPONENT}
									className="footer-link footer-text"
									shouldOpenInNewTab={false}
								>
									{messages.blog}
								</LinkExternal>
							</li>
							<li>
								<LinkInternal
									to={ROUTES.dolby}
									type={LinkType.LINK_STYLED_IN_COMPONENT}
									className="footer-link footer-text"
								>
									{messages.dolby}
								</LinkInternal>
							</li>
							<li>
								<LinkInternal
									to={ROUTES.apps}
									type={LinkType.LINK_STYLED_IN_COMPONENT}
									className="footer-link footer-text"
								>
									{messages.apps}
								</LinkInternal>
							</li>
							<li>
								<LinkInternal
									to={ROUTES.contact}
									type={LinkType.LINK_STYLED_IN_COMPONENT}
									className="footer-link footer-text"
								>
									{messages.contact_us}
								</LinkInternal>
							</li>
						</ul>
					</div>
					<div className="footer__menu-group footer__menu-links col-md-4 col-lg-4">
						<div className="footer__menu-heading">
							<p className="footer__title">{messages.services}</p>
						</div>
						<ul>
							<li>
								<LinkInternal
									to={ROUTES.our_services}
									type={LinkType.LINK_STYLED_IN_COMPONENT}
									className="footer-link footer-text"
								>
									{messages.our_services}
								</LinkInternal>
							</li>
							<li>
								<LinkInternal
									to={ROUTES.production}
									type={LinkType.LINK_STYLED_IN_COMPONENT}
									className="footer-link footer-text"
								>
									{messages.production}
								</LinkInternal>
							</li>
							<li>
								<LinkInternal
									to={ROUTES.distribution_and_delivery}
									type={LinkType.LINK_STYLED_IN_COMPONENT}
									className="footer-link footer-text"
								>
									{messages.distribution_and_delivery}
								</LinkInternal>
							</li>
							<li>
								<LinkInternal
									to={ROUTES.marketing_and_pr}
									type={LinkType.LINK_STYLED_IN_COMPONENT}
									className="footer-link footer-text"
								>
									{messages.marketing_and_pr}
								</LinkInternal>
							</li>
						</ul>
					</div>

					<div className="footer__menu-group footer__menu-links col-md-4 col-lg-4">
						<div className="footer__menu-heading">
							<p className="footer__title">{messages.footer_help}</p>
						</div>
						<ul className="footer-text">
							<li>
								<LinkExternal
									to={ROUTES.helpdesk}
									type={LinkType.LINK_STYLED_IN_COMPONENT}
									className="footer-link footer-text"
									shouldOpenInNewTab={false}
								>
									{messages.helpdesk}
								</LinkExternal>
							</li>
							<li>
								<LinkExternal
									to={ROUTES.supported_devices}
									type={LinkType.LINK_STYLED_IN_COMPONENT}
									className="footer-link footer-text"
									shouldOpenInNewTab={false}
								>
									{messages.supported_devices}
								</LinkExternal>
							</li>
							<li>
								<LinkExternal
									to={ROUTES.payment_methods}
									type={LinkType.LINK_STYLED_IN_COMPONENT}
									className="footer-link footer-text"
									onClick={trackHelpdeskViewed}
									shouldOpenInNewTab={false}
								>
									{messages.payment_methods}
								</LinkExternal>
							</li>
							<li>
								<LinkExternal
									to={ROUTES.troubleshooting}
									type={LinkType.LINK_STYLED_IN_COMPONENT}
									className="footer-link footer-text"
									onClick={trackHelpdeskViewed}
									shouldOpenInNewTab={false}
								>
									{messages.troubleshooting}
								</LinkExternal>
							</li>
						</ul>
					</div>
				</div>

				<div className="d-flex flex-column justify-content-center align-items-center text-align-center flex-md-row justify-content-md-start align-items-md-stretch col-md-12 order-1 order-md-2">
					<div className="footer__menu-group col-md-6 col-lg-4 col-xl-4">
						<div className="footer__logo d-flex justify-content-center justify-content-md-start">
							<LinkInternal
								to="/"
								type={LinkType.LINK_STYLED_IN_COMPONENT}
								className="footer__logo__icon d-flex align-items-center p-0"
								ariaLabel="navigate to home page"
							>
								<OnairLogo />
							</LinkInternal>
						</div>
						<div className="footer__socials order-3 d-none d-md-flex">
							<Socials links={onAirSocialsLinks} />
						</div>
						<div className="footer__socials d-flex d-md-none justify-content-center">
							<Socials links={onAirSocialsLinks} />
						</div>
					</div>
					<div className="d-none d-md-flex">
						<Logos />
					</div>
				</div>
			</div>

			<div className="d-flex flex-column flex-md-row d-flex d-md-none order-4">
				<Logos centered />
			</div>

			<div className="footer__bottombar-copyright d-flex flex-grow-1 justify-content-center">
				<p className="copyright-text">
					© {new Date().getFullYear()} {messages.copyrights}{" "}
					<br className="d-inline-block d-md-none" />
					<LinkInternal
						to={ROUTES.termsAndConditions}
						type={LinkType.LINK_STYLED_IN_COMPONENT}
					>
						{messages.terms_and_conditions}
					</LinkInternal>
					,{" "}
					<LinkInternal
						to={ROUTES.privacyPolicy}
						type={LinkType.LINK_STYLED_IN_COMPONENT}
					>
						{messages.privacy_policy}
					</LinkInternal>{" "}
					&{" "}
					<LinkInternal
						to={ROUTES.cookiePolicy}
						type={LinkType.LINK_STYLED_IN_COMPONENT}
					>
						{messages.cookies}
					</LinkInternal>
					.
				</p>
			</div>
		</div>
	</footer>
);

export default Footer;
