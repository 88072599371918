import React, { FC } from 'react';
import LinkInternal from '../LinkInternal';
import ROUTES from '../../constants/routes';
import FourKLogo from '../svgs/FourKLogo';
import DolbyVisionAtmosLogo from '../svgs/DolbyVisionAtmosLogo';
import WePlantTreesLogo from '../svgs/WePlantTreesLogo';
import messages from './messages';
import { AppStoreBanner } from '../app-store-banner';
import { LinkType } from 'onair_frontend-lib';

export interface LogosProps {
    centered?: boolean;
}
export const Logos: FC<LogosProps> = ({ centered }) => (
    <div
        className={`footer__menu-group d-flex flex-column justify-content-center justify-content-md-start col-md-6 col-lg-4 flex-grow-1 ${
            centered ? 'mx-auto justify-content-between' : ''
        }`}
    >
        <AppStoreBanner component="footer" />
        <div className="footer__logo-row d-flex align-items-center flex-grow-1 my-3 my-md-0">
            <div className="footer__logo-item d-flex flex-row justify-content-center">
                <div className="logo-spacing app-store-banner__icon">
                    <FourKLogo />
                </div>
                <LinkInternal
                    to={ROUTES.dolby}
                    className="footer__logo__icon d-flex align-items-center p-0"
                    type={LinkType.LINK_STYLED_IN_COMPONENT}
                >
                    <DolbyVisionAtmosLogo />
                </LinkInternal>
            </div>
            <div className="footer__logo-item d-flex flex-row align-items-center">
                <LinkInternal
                    to={ROUTES.trees}
                    className="footer__logo__icon d-flex align-items-center p-0"
                    type={LinkType.LINK_STYLED_IN_COMPONENT}
                >
                    <div className="footer__trees d-flex flex-row justify-content-center align-items-center p-0">
                        <WePlantTreesLogo />
                        <p className="footer-text mx-1 mx-md-0">
                            {messages.trees}
                        </p>
                    </div>
                </LinkInternal>
            </div>
        </div>
    </div>
);
