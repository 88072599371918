import React, { FC } from 'react';
// import { useTranslation } from 'react-i18next';
import LinkInternal from '../LinkInternal';
import ROUTES from '../../constants/routes';
import Icon from '../Icon';

const NavbarAccount: FC<{
    firstName: string;
    lastName: string;
    setMyPlaceMobileMenuExpanded: (value: boolean) => void;
}> = ({ firstName, lastName, setMyPlaceMobileMenuExpanded }) => {
    // const { t } = useTranslation();

    return (
        <div className="col navbar__item navbar__item--profile flex-grow-0">
            <LinkInternal
                to={ROUTES.myPlace}
                className="navbar__profile--wide d-none d-lg-flex pull-right"
                ariaLabel='Navigate to My Place'
                // ariaLabel={`${t('global:navigate_to')} ${t(
                //     'homepage:menu.my_place',
                // )}`}
            >
                <div className="navbar__item--profile-text">
                    <p className="caption-title">
                        {/* {t('homepage:menu.welcome')} */}
                        Welcome
                    </p>
                    <h6 className="caption-title">{firstName}</h6>
                </div>
                <Icon icon="oa-user" />
            </LinkInternal>
            <button
                type="button"
                aria-label="toggle my place menu"
                data-testid="navbar__link-hamburger-my-place"
                className="navbar__profile--small d-flex d-lg-none justify-content-center align-items-center"
                onClick={() => setMyPlaceMobileMenuExpanded(true)}
            >
                {`${firstName.charAt(0).toUpperCase()}${lastName
                    .charAt(0)
                    .toUpperCase()}`}
            </button>
        </div>
    );
};

export default NavbarAccount;
