export default {
    my_place: 'My Place',

    side_navigation: {
        my_place: 'My Place',
        shows: 'Shows',
        profile_settings: 'Profile settings',
        log_out: 'Log out',
    },

    main: {
        my_place: 'My Place',
        personal_information:
            'Here you will find all your personal information.',
        hello: 'Hello',
        show_section_title: 'Your latest purchased show',
        no_shows_purchased_yet: 'You do not have any shows yet.',
        link_to_my_shows: 'Your purchased shows',
        browse_our_shows: 'Browse our shows',
    },

    profile_settings: {
        change_profile: {
            title: 'Profile settings',
            primary_cta: 'Save changes',
            change_email_link: 'Change email',
            change_password_link: 'Change password',
            export_my_data_link: 'Export my data',
            request_account_deletion_link: 'Request account deletion',
        },
        change_email: {
            title: 'Change email address',
            primary_cta: 'Change email address',
            secondary_cta: 'Cancel',
        },
        change_password: {
            change_password: 'Change password',
            current_password: 'Current password',
            forgot_password: 'Forgot password?',
            new_password: 'New password',
            cancel: 'Cancel',
        },
    },

    my_tickets: {
        title: 'My Shows',
        past_events: 'Past Shows',
        error_fetching_tickets:
            'Something is going wrong on our side, the team has been notified about this issue. Please try again later.',
        empty_state_body: 'No upcoming shows',
        browse_shows: 'Browse shows',
        expired_on: 'Expired on:',
        available_until: 'Available until:',
        available_from: 'Available from:',
    },
};
