import React, { FC } from 'react';
// import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ROUTES from '../../constants/routes';
import Icon from '../Icon';
import LinkInternal from '../LinkInternal';
import { webPurchaseTrack } from 'onair_frontend-lib';
import { sharedAnalyticsProperties } from '@/utils/analytics';
import { useAppSelector } from '@/state/hooks';
import { getCartItemCurrency } from '../cart/utils';

const NavbarCart: FC<{
    itemsInCart?: number;
}> = ({ itemsInCart }) => {
    // const { t } = useTranslation();
    const { cart } = useAppSelector((state) => state.cartSlice);
    const priceCurrency = cart && getCartItemCurrency(cart);
    const normalizedCartList = useAppSelector(
        (state) => state.cartSlice.normalizedCartList,
    );

    const handleTrackCartViews = () => {
        webPurchaseTrack({
            ...sharedAnalyticsProperties,
            trackCallback: window?.analytics?.track,
            purchaseStatus: 'Cart Viewed',
            cartItems: normalizedCartList,
            currency: priceCurrency,
            cartId: cart?.id,
            url: window.location.href,
        });
    };
    return (
        <div
            className={classNames(
                'col navbar__link navbar__link--icon navbar__link--cart flex-grow-0',
                {
                    'navbar__link--cart-filled': (itemsInCart || 0) > 0,
                },
            )}
        >
            <LinkInternal
                to={ROUTES.cart}
                ariaLabel='Navigate to My Cart'
                // ariaLabel={`${t('global:navigate_to')} ${t(
                //     'homepage:menu.my_cart',
                // )}`}
                onClick={handleTrackCartViews}
            >
                <Icon icon="oa-cart" />
                <span>{itemsInCart}</span>
            </LinkInternal>
        </div>
    );
};

export default NavbarCart;
