import React from 'react';

// import { useTranslation } from 'react-i18next';
import LinkInternal from '../LinkInternal';
import ROUTES from '../../constants/routes';
import Icon from '../Icon';

import type { FC } from 'react';
import {
    navigateActions,
    type FocusedRoute,
} from '@/state/reducers/navigationSlice';

import { useAppDispatch, useAppSelector } from '@/state/hooks';

const NavbarNoAccount: FC = () => {
    // const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { focusedRoute } = useAppSelector((state) => state.navigation);

    /**
     * Workaround to pass focus on "/login" or "/register" route
     * event if the navigation occurred via "window.history.replaceState"
     * instead of using "next/navigation"
     */
    const handleIsFocused = (route: FocusedRoute) => {
        // If the focused route is undefined use window.location.href
        // instead of "usePathname"
        if (!focusedRoute && typeof window !== 'undefined') {
            return window?.location?.href?.includes(route);
        }
        return focusedRoute === route;
    };

    /**
     * Clear redux state if you use the navbar links for navigation
     */
    const handleClearForceFocus = () => {
        dispatch(navigateActions.setFocusedRoute(undefined));
    };

    return (
        <>
            <div className="col navbar__link navbar__link--icon d-block d-lg-none flex-grow-0">
                <LinkInternal
                    to={ROUTES.myPlace}
                    ariaLabel="Navigate to My Place"
                    // ariaLabel={`${t('global:navigate_to')} ${t(
                    //     'homepage:menu.my_place',
                    // )}`}
                >
                    <Icon icon="oa-user" />
                </LinkInternal>
            </div>
            <div className="col navbar__link d-none d-lg-flex flex-grow-0">
                <LinkInternal
                    ariaLabel="Navigate to Log in"
                    // ariaLabel={`${t('global:navigate_to')} ${t(
                    //     'homepage:menu.login',
                    // )}`}
                    to={ROUTES.login}
                    isFocused={handleIsFocused(ROUTES.login)}
                    onClick={handleClearForceFocus}
                >
                    {/* {t('homepage:menu.login')} */}
                    Log in
                </LinkInternal>
            </div>
            <div className="col navbar__link d-none d-lg-flex flex-grow-0">
                <LinkInternal
                    ariaLabel="Navigate to Register"
                    // ariaLabel={`${t('global:navigate_to')} ${t(
                    //     'homepage:menu.register',
                    // )}`}
                    to={ROUTES.register}
                    isFocused={handleIsFocused(ROUTES.register)}
                    onClick={handleClearForceFocus}
                >
                    {/* {t('homepage:menu.register')} */}
                    Register
                </LinkInternal>
            </div>
        </>
    );
};

export default NavbarNoAccount;
