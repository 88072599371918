import { useEffect, useState } from 'react';

export default () => {
    const [isClient, setClient] = useState(false);

    useEffect(() => {
        setClient(true);
    }, []);

    return isClient;
};
