'use client';
import React, { useEffect } from 'react';

import messages from './messages';

import { ErrorComponent, ErrorOption } from '../../error';
import Shell from '../../Shell';
import ROUTES from '../../../constants/routes';
import LinkInternal from '../../LinkInternal';

import type { OGP } from '../../../types/page-context';
import { logPageViewToSegment } from '@/utils/segment';

// Convert "bla bla ${link} bla" into valid JSX for inside <ErrorOption>
function optionStringToJSX(text: string, linkText: string, linkTo: string) {
    // eslint-disable-next-line no-template-curly-in-string
    const keyword = '${link}';
    const linkStart = text.indexOf(keyword);
    const linkEnd = linkStart >= 0 ? linkStart + keyword.length : 0;
    return (
        <>
            {linkStart > 0 ? text.slice(0, Math.max(0, linkStart)) : undefined}
            {linkStart >= 0 ? (
                <LinkInternal to={linkTo}>{linkText}</LinkInternal>
            ) : undefined}
            {linkEnd < text.length
                ? text.slice(Math.max(0, linkEnd))
                : undefined}
        </>
    );
}

const ogp: OGP = {
    title: messages.notFound.doc_title,
    description: messages.notFound.doc_description,
    slug: '',
};

const NotFoundPage = () => {
    const {
        title,
        message,
        option_title,
        option_help,
        option_help_link,
        option_home,
        option_home_link,
    } = messages.notFound;
    useEffect(() => {
        logPageViewToSegment('/404');
    }, []);
    return (
        <Shell ogp={ogp}>
            <div className="error-wrap pb-10">
                <ErrorComponent
                    title={title}
                    message={message}
                    optionsTitle={option_title}
                >
                    <ErrorOption icon="oa-chevron-right">
                        {optionStringToJSX(
                            option_help,
                            option_help_link,
                            ROUTES.help,
                        )}
                    </ErrorOption>
                    <ErrorOption icon="oa-chevron-right">
                        {optionStringToJSX(
                            option_home,
                            option_home_link,
                            ROUTES.home,
                        )}
                    </ErrorOption>
                </ErrorComponent>
            </div>
        </Shell>
    );
};

export default NotFoundPage;
