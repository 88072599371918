'use client';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ROUTES from '../../constants/routes';
import OnairLogo from '../svgs/OnairLogo';
// import SideNavigation from '../my-place/SideNavigation';
import LinkInternal from '../LinkInternal';
import Icon from '../Icon';
import LinkExternal from '../LinkExternal';
import { LinkType } from 'onair_frontend-lib';
import { trackHelpdeskViewed } from '@/utils/analytics';
import SideNavigation from '../my-place/SideNavigation';

type NavbarMobileProps = {
    loggedIn: boolean;
    isClient: boolean;
    firstName?: string;
    defaultMobileMenuExpanded: boolean;
    myPlaceMobileMenuExpanded: boolean;
    setDefaultMobileMenuExpanded: (newValue: boolean) => void;
    setMyPlaceMobileMenuExpanded: (newValue: boolean) => void;
};

// It might seem weird that it's double, but its possible to click one menu while the other is open
// To prevent weird re-rendering glitches from happening in the content it is double
const NavbarMobile: FC<NavbarMobileProps> = ({
    loggedIn,
    isClient,
    firstName,
    myPlaceMobileMenuExpanded,
    defaultMobileMenuExpanded,
    setDefaultMobileMenuExpanded,
    setMyPlaceMobileMenuExpanded,
}) => {
    const mobileSearchRef = useRef<HTMLInputElement>(null);
    const [mobileSearchExpanded, setMobileSearchExpanded] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    const expandSearch = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();

        if (mobileSearchRef && mobileSearchRef.current) {
            mobileSearchRef.current.focus();
            setMobileSearchExpanded(true);
        }
    }, []);

    const doSearch = () => {
        // eslint-disable-next-line no-alert
        alert('Searching...');
    };

    const mobileClick = useCallback(() => {
        setMobileSearchExpanded(false);
        setMyPlaceMobileMenuExpanded(false);
    }, [setMyPlaceMobileMenuExpanded]);

    const hideMenus = useCallback(() => {
        setMyPlaceMobileMenuExpanded(false);
        setDefaultMobileMenuExpanded(false);
    }, [setDefaultMobileMenuExpanded, setMyPlaceMobileMenuExpanded]);

    // const { t } = useTranslation();

    // This is a workaround for the hydration error. Not so clean tho. Maybe we can find something better?
    // This is caused by the loggedIn ternary in the return. The loggedIn variable is not defined on the server side.
    if (!isMounted) return null;

    return (
        <>
            <div
                className={classNames('navbar__mobile navbar__mobile--left', {
                    'navbar__mobile--visible': defaultMobileMenuExpanded,
                })}
                onClick={mobileClick}
                aria-hidden="true"
                data-testid="navbar__mobile--left"
            >
                <div className="navbar-mobile__head">
                    <button
                        className="head__close"
                        aria-label="close main menu"
                        type="button"
                        onClick={() => setDefaultMobileMenuExpanded(false)}
                    >
                        <Icon icon="oa-close" />
                    </button>
                    <form
                        onSubmit={doSearch} // TODO remove d-none from here when enabling search again
                        className={classNames('d-none', 'head__input-wrapper', {
                            'head__input-wrapper--expanded':
                                mobileSearchExpanded,
                        })}
                    >
                        <input ref={mobileSearchRef} onClick={expandSearch} />
                        <Icon
                            icon="oa-search"
                            className="head__expand"
                            onClick={expandSearch}
                            label="Search"
                        />
                        <Icon
                            icon="oa-search"
                            className="head__search"
                            onClick={doSearch}
                            label="Search"
                        />
                    </form>
                </div>
                <div className="navbar-mobile__body">
                    <LinkInternal
                        type={LinkType.LINK_STYLED_IN_COMPONENT}
                        to={ROUTES.home}
                        onClick={hideMenus}
                        ariaLabel='Navigate to Shows'
                        // ariaLabel={`${t('global:navigate_to')} ${t(
                        //     'homepage:menu.shows',
                        // )}`}
                    >
                        {/* {t('homepage:menu.shows')} */}
                        Shows
                        
                    </LinkInternal>
                    <LinkInternal
                        to={ROUTES.about}
                        type={LinkType.LINK_STYLED_IN_COMPONENT}
                        onClick={hideMenus}
                        ariaLabel='Navigate to About'
                        // ariaLabel={`${t('global:navigate_to')} ${t(
                        //     'homepage:menu.about',
                        // )}`}
                    >
                        {/* {t('homepage:menu.about')} */}
                        About
                    </LinkInternal>
                    <LinkExternal
                        to={ROUTES.help}
                        ariaLabel='Navigate to Help'
                        // ariaLabel={`${t('global:navigate_to')} ${t(
                        //     'homepage:menu.help',
                        // )}`}
                        onClick={trackHelpdeskViewed}
                        shouldOpenInNewTab={false}
                    >
                        {/* {t('homepage:menu.help')} */}
                        Help
                    </LinkExternal>
                    {loggedIn ? (
                        <LinkInternal
                            className="mt-3 d-flex"
                            to={ROUTES.myPlace}
                            type={LinkType.LINK_STYLED_IN_COMPONENT}
                            onClick={hideMenus}
                            ariaLabel='Navigate to My Place'
                            // ariaLabel={`${t('global:navigate_to')} ${t(
                            //     'homepage:menu.my_place',
                            // )}`}
                        >
                            <Icon icon="oa-user" /> My Place
                            {/* {t('homepage:menu.my_place')} */}
                        </LinkInternal>
                    ) : (
                        <>
                            <LinkInternal
                                className="mt-3"
                                to={ROUTES.login}
                                type={LinkType.LINK_STYLED_IN_COMPONENT}
                                onClick={hideMenus}
                                ariaLabel='Navigate to Log in'
                                // ariaLabel={`${t('global:navigate_to')} ${t(
                                //     'homepage:menu.login',
                                // )}`}
                            >
                                {/* {t('homepage:menu.login')} */}
                                Log in
                            </LinkInternal>
                            <LinkInternal
                                to={ROUTES.register}
                                type={LinkType.LINK_STYLED_IN_COMPONENT}
                                onClick={hideMenus}
                                ariaLabel='Navigate to Register'
                                // ariaLabel={`${t('global:navigate_to')} ${t(
                                //     'homepage:menu.register',
                                // )}`}
                            >
                                {/* {t('homepage:menu.register')} */}
                                Register
                            </LinkInternal>
                        </>
                    )}
                </div>
                <div className="navbar-mobile__footer">
                    <OnairLogo />
                </div>
            </div>

            {loggedIn && isClient ? (
                <div
                    className={classNames(
                        'navbar__mobile navbar__mobile--right',
                        {
                            'navbar__mobile--visible':
                                myPlaceMobileMenuExpanded,
                        },
                    )}
                    onClick={mobileClick}
                    aria-hidden="true"
                    data-testid="navbar__mobile--right"
                >
                    <div className="navbar-mobile__head navbar-mobile__head--border">
                        <p className="head__profile-name section-title">
                            Welcome {firstName || ''}
                            {/* {t('homepage:menu.welcome')} {firstName || ''} */}
                        </p>
                        <Icon
                            icon="oa-close"
                            className="head__close"
                            onClick={() => setMyPlaceMobileMenuExpanded(false)}
                            label="Close sidebar"
                        />
                    </div>
                    <div className="navbar-mobile__body">
                        {/* TO DO - Bring back Side Navigation */}
                        <div className="navbar-mobile__my-place">
                            <SideNavigation />
                        </div>
                    </div>
                    <div className="navbar-mobile__footer">
                        <OnairLogo />
                    </div>
                </div>
            ) : null}

            <div
                className={classNames('navbar__mobile-transparent', {
                    'navbar__mobile--visible':
                        defaultMobileMenuExpanded || myPlaceMobileMenuExpanded,
                })}
                onClick={hideMenus}
                aria-hidden="true"
            />
        </>
    );
};

export default NavbarMobile;
