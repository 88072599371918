export default {
    banner: {
        title: 'This show will go ',
        title_second_part: 'On Air ',
        title_third_part: 'in: ',
    },
    bannerLive: {
        title: 'This show is ',
        title_second_part: 'On Air, ',
        title_third_part: 'ticket sale closes in: ',
    },
    bought_event_countdown: {
        title: 'Doors open in',
        subtitle: 'You have bought a ticket for this show!',
        watch_live: 'Watch Live',
    },
    live_event_sale_closed: {
        title: 'This show is live!',
        subtitle: 'Tickets sale is closed',
        login_title: 'Already bought a ticket?',
        login_body_text: 'Log in to see your ticket',
    },
    past_event_not_purchased: {
        title: 'This show is in the past',
        subtitle_part_1: 'Never want to miss a show like ',
        follow_button_text: 'Follow @onairevents',
    },
    past_event_purchased: {
        title: 'This show is in the past',
        body_title: 'What did you think of ',
        body_text: 'Share it with the world:',
    },
    not_for_sale: {
        title: 'This show is not for sale in your country',
    },
    only_for_sale_with_partner: {
        notification_title_1: 'This show is only for sale through our partner ',
        notification_title_2: ' in your country',
        buy_from_partner_text: 'Purchase show from partner',
    },
    error: {
        title: 'Oops',
        message:
            'A problem has occurred, our apologies for the inconvenience. The On Air team has been notified and will be working to resolve it shortly.',
        option_title: "In the meantime, here's what you can do:",
        option_refresh: 'Refresh the page',
        option_wait: 'Try again after 30 minutes',
        option_contact: 'Contact helpdesk',
    },
    forbidden: {
        title: 'Forbidden',
        message: 'You do not seem to have access to this video',
    },
    no_stream_urls: {
        title: 'No stream URLs',
        message: 'There are no stream URLs for this event',
    },
};
