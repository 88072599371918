const ROUTES = {
	home: "/",
	login: "/login",
	register: "/register",
	forgotPassword: "/forgot-password",
	forgotPasswordCheckYourEmail: "/forgot-password/check-your-email",
	cart: "/cart",
	connect: "/connect",

	about: "/about-us",
	help: "//helpdesk.onair.events",
	dolby: "/dolby",
	apps: "/apps",
	trees: "/one-tree-planted",

	myPlace: "/my-place",
	myShows: "/my-place/shows/",
	shows: "/shows",
	profileSettings: "/profile-settings",
	changeEmail: "/change-email",
	changePassword: "/change-password",
	contact: "/contact",
	press: "/press-releases",
	search: "/search",

	cookiePolicy: "/cookie-policy",
	privacyPolicy: "/privacy-policy",
	termsAndConditions: "/terms-and-conditions",

	// Services
	our_services: "/our-services",
	production: "/services/production",
	distribution_and_delivery: "/services/distribution-and-delivery",
	marketing_and_pr: "/services/marketing-and-PR",

	// Support
	helpdesk: "https://helpdesk.onair.events/support/home",
	supported_devices:
		"https://helpdesk.onair.events/en/support/solutions/articles/80001074841-what-are-the-supported-devices-and-browsers",
	payment_methods:
		"https://helpdesk.onair.events/en/support/solutions/articles/80000969517-what-are-the-supported-payment-methods",
	troubleshooting:
		"https://helpdesk.onair.events/en/support/solutions/folders/80000703130",
	payments_and_orders:
		"https://helpdesk.onair.events/support/solutions/folders/80000329625",

	news: "https://allthingslive.onair.events/",

	appleStore: "//apps.apple.com/us/app/on-air-all-things-live/id1594595623",
	playStore: "//play.google.com/store/apps/details?id=events.onair",
};

export default ROUTES;
