import React from "react";

import { ErrorMessageBanner } from "./ErrorMessageBanner";
import { EventCountdownBanner } from "./EventCountdownBanner";

import type { FC } from "react";
import type { EventCountdownBannerProps } from "./EventCountdownBanner";

type EventCountdownBannerComponentProps = EventCountdownBannerProps & {
	type: "EventCountdown";
	hideBanner?: null;
};

type ErrorMessageBannerComponentProps = {
	type: "ErrorMessage";
	hideBanner?: () => void;
};

type MessageBannerProps =
	| EventCountdownBannerComponentProps
	| ErrorMessageBannerComponentProps;

export const MessageBanner: FC<MessageBannerProps> = ({
	type,
	hideBanner,
	...rest
}) => {
	switch (type) {
		case "EventCountdown": {
			const { startDate, playingNow } =
				rest as EventCountdownBannerComponentProps;
			return (
				<EventCountdownBanner startDate={startDate} playingNow={playingNow} />
			);
		}

		case "ErrorMessage": {
			return <ErrorMessageBanner hideBanner={hideBanner} />;
		}

		default:
			return null;
	}
};
