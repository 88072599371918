import React from 'react';
import { LinkType } from 'onair_frontend-lib';

import messages from './messages';

import LinkInternal from '../LinkInternal';
import ROUTES from '../../constants/routes';
import { useAppDispatch } from '../../state/hooks';
import Icon from '../Icon';
import { handleLogout } from '../../state/tokenHelper';

import type { FC } from 'react';

// Keep in mind, this is used in multiple places
const SideNavigation: FC = () => {
    const dispatch = useAppDispatch();

    return (
        <div className="side-navigation d-flex flex-column">
            <LinkInternal
                type={LinkType.LINK_STYLED_IN_COMPONENT}
                to={ROUTES.myPlace}
                className="d-flex"
            >
                <Icon icon="oa-home" /> {messages.side_navigation.my_place}
            </LinkInternal>
            <LinkInternal
                type={LinkType.LINK_STYLED_IN_COMPONENT}
                to={`${ROUTES.myPlace}${ROUTES.shows}`}
                className="d-flex"
            >
                <Icon icon="oa-ticket" /> {messages.side_navigation.shows}
            </LinkInternal>
            <LinkInternal
                type={LinkType.LINK_STYLED_IN_COMPONENT}
                to={`${ROUTES.myPlace}${ROUTES.profileSettings}`}
                className="d-flex"
            >
                <Icon icon="oa-user" />{' '}
                {messages.side_navigation.profile_settings}
            </LinkInternal>

            <button
                type="button"
                onClick={() => handleLogout(dispatch)}
                className="d-flex"
            >
                <Icon icon="oa-logout" /> {messages.side_navigation.log_out}
            </button>
        </div>
    );
};

export default SideNavigation;
