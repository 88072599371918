import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

type InputErrorProps = {
    testId: string;
    align?: 'left' | 'center' | 'right';
    children: ReactNode | string
};

const InputError: FC<InputErrorProps> = ({ testId, align, children }) => (
    <p
        className={classNames('onair-input-error caption-text', {
            [`text-${align}`]: !!align,
        })}
        data-testid={testId}
    >
        {children}
    </p>
);

export default InputError;
