import React from 'react';
import classNames from 'classnames';
import { LinkType } from 'onair_frontend-lib';

import LinkExternal from './LinkExternal';

import type { FC } from 'react';

export type IconLink = {
    href: string;
    icon: string;
};
export interface SocialsProps {
    links: IconLink[];
    className?: string;
}

const Socials: FC<SocialsProps> = ({ links, className }) => {
    const needFix = ['oa-facebook', 'oa-instagram1', 'oa-linkedin'];

    return (
        <div className={classNames('socials', className)}>
            {links?.length > 0 ? (
                <div className="socials__link-wrapper">
                    {links.map(({ href, icon }) => (
                        <LinkExternal
                            key={href}
                            to={href}
                            icon={icon}
                            className={classNames(
                                'body-text d-flex align-items-center justify-content-center',
                                {
                                    'socials__icon-spacing-fix':
                                        needFix.includes(icon),
                                },
                            )}
                            type={LinkType.LINK_TERTIARY}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default Socials;
